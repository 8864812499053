import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Izzy Young | Web Dev', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my Portfolio Website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: `Welcome! My name is`,
  name: 'Izzy Young',
  subtitle: `I'm a Web Developer.`,
  cta: 'Let me introduce myself',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpeg',
  paragraphOne: `I'm as much of a hobbyist developer as I am a professional one. I love coding, and I love teaching people code. Whether it is resolving a difficult ticket, or helping a junior developer reach that eureka moment, I love what I do.`,
  paragraphTwo: `I believe in egoless development that garners a maintainable, readable codebase that truly serves the user.  I always want to be quick to listen, quick to acknowledge the achievements of my peers, and to strive towards mastery of my craft as a developer.`,
  paragraphThree: `Thanks for taking time to read all of that, I would love to learn more about you, and hopefully we can meet soon. :)`,
  resume: `https://docs.google.com/presentation/d/1aIm8f-YP4WPzRmjibHd39zL_vdJz-Vq3qJsXLhKKX9A/edit?usp=sharing`, // if no resume, the button will not show up
};

// PROJECTS DATA
// Image: 615 x 440
export const projectsData = [
  {
    id: nanoid(),
    img: 'eu-manscaped.jpg',
    title: 'European Launch',
    info: `Replicated and built roughly 80% of the frontend for the European launch with my senior developer. This was by far the most stressful project I worked on, requiring to pull several all-nighters during launch week. I painstakingly QAed this project, and discovered an obscure checkout-breaking, discount bug 5 minutes before the launch, which my boss was enthused about because this could have potentially costed us millions in revenue.`,
    techStack: `Gatsby.js, React.js, React Spring, Shopify, GraphQL, and more`,
    url: 'https://eu.manscaped.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'manscaped-php.jpg',
    title: 'Peak Hygiene Plan Backend Project',
    info: `Repaired and prepped the groundwork for our premium flagship subscription plan.`,
    techStack: `Node.JS, Express.js, Mongoose, and more`,
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'manscaped-products.jpg',
    title: 'Product Pages',
    info: `Built and maintaineded  product pages for both our old and new site.`,
    techStack: `React.js, Gatsby.js, Shopify Liquid, HTML, CSS, jQuery, Express.js, Mongoose, MongoDB`,
    url: 'https://au.manscaped.com/products/weed-whacker',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'manscaped-student.jpg',
    title: 'Discount Pages - Student, Medical, & First Responders',
    info: `Placed in full responsibility of the front end of our discount pages. I also integrated them with our backedn and ID.me to provide verified discounts`,
    techStack: `Shopify Liquid, HTML, CSS, jQuery, Express.js, Mongoose, MongoDB`,
    url: 'https://www.manscaped.com/pages/student-discounts',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'manscaped-help.jpg',
    title: 'Help Page & Support Automations',
    info: `Was the primary developer responsible for maintainng our customer support workflows and pages, including the chat and a deflection email automation that saves our support team countless hours in solving support tickets.`,
    techStack: `HTML, CSS, jQuery, Kustomer`,
    url: 'https://help.manscaped.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'minimaliste.png',
    title: 'Minimaliste',
    info: `Led and managed UI/UX design. Programmed core e-commerce functionality from scratch: allowing users to login, store items in the cart, and purchase products with Stripe. Contributed over 58% of the commits`,
    techStack: `MongoDB, Mongoose ORM, Express, React, Node, Bulma CSS, Stripe API, Bcrypt Hashing, Heroku `,
    url: 'https://minimaliste.herokuapp.com/',
    repo: 'https://github.com/tenthyoung/Minimaliste', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'pizzaprestoSmall.png',
    title: 'Pizza Presto',
    info: `Overhauled the game design, consolidated graphics and animations, developed and debugged the core game functionality and relayed scores to database. Contributed over 70.2% of the commits`,
    techStack: `HTML, CSS, Materialize, Javascript, jQuery, Trivia API, Firebase`,
    url: 'https://tenthyoung.github.io/PizzaPresto/',
    repo: 'https://github.com/tenthyoung/PizzaPresto', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'pokemonClicker.png',
    title: 'Pokemon Clicker',
    info: 'A simple SPA game I made.',
    techStack: 'React.js',
    url: 'https://tenthyoung.github.io/Pokemon-Clicker/',
    repo: 'https://github.com/tenthyoung/Pokemon-Clicker', // if no repo, the button will not show up;
  },
  {
    id: nanoid(),
    img: 'valueDating.png',
    title: 'Value Dating',
    info: `Are you tired of being single? Improve your chances of finding a match who shares the same values as you. Enter in the form, and our algorithms will find the best match for you.`,
    techStack: 'HTML, CSS, Materialize CSS, Javascript, jQuery, Express JS',
    url: 'https://valuedating.herokuapp.com/',
    repo: 'https://github.com/tenthyoung/Value-Datiing', // if no repo, the button will not show up;
  },
  {
    id: nanoid(),
    img: 'liri.png',
    title: 'LIRI Bot',
    info: `LIRI is a Language Interpretation and Recognition Interface. LIRI will be a command line node app that takes in parameters and gives you back data. LIRI will search Spotify for songs, Bands in Town for concerts, and OMDB for movies.`,
    techStack: 'Node.js',
    url: 'https://github.com/tenthyoung/LIRI-Node-App',
    repo: 'https://github.com/tenthyoung/LIRI-Node-App', // if no repo, the button will not show up;
  },
  {
    id: nanoid(),
    img: 'Giftastic.png',
    title: 'Giftastic',
    info: `A simple website that uses the Giphy API to generate Gifs that the user asks for.`,
    techStack: 'HTML, CSS, Javascript',
    url: 'https://tenthyoung.github.io/Giftastic/',
    repo: 'https://github.com/tenthyoung/Giftastic', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'PokemonMathFighter.png',
    title: 'Pokémon Math Fighter',
    info: `Solve math problems quickly to increase your odds of hitting critical damage on your opponent!`,
    techStack: 'HTML, CSS, jQuery, Javascript',
    url: 'https://tenthyoung.github.io/Pokemon-Math-Fighter/',
    repo: 'https://github.com/tenthyoung/Pokemon-Math-Fighter', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'DefeatThanos.png',
    title: 'Defeat Thanos',
    info: `Click on a hero to do damage. If you reduce Thanos' health exactly to 0, you win`,
    techStack: 'HTML, CSS, Javascript',
    url: 'https://tenthyoung.github.io/DefeatThanosGame/',
    repo: 'https://github.com/tenthyoung/DefeatThanosGame', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'colorGame.png',
    title: 'Color Game',
    info: 'A simple RGB color guessing game.',
    techStack: 'HTML, CSS, Javascript',
    url: 'https://tenthyoung.github.io/Color-Game/',
    repo: 'https://github.com/tenthyoung/Color-Game', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'LOTR.png',
    title: 'Lord of the Rings | Hangman',
    info: `Save Middle Earth by solving Golem's riddles. Be careful, if you guess wrong, he will hit you with a rock!`,
    techStack: 'HTML, CSS, Javascript',
    url: 'https://tenthyoung.github.io/LordOfTheRings-Hangman/',
    repo: 'https://github.com/tenthyoung/LordOfTheRings-Hangman', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Thank you for your time and consideration!',
  btn: `Let's get connected`,
  email: 'tenthyoung@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/tenthyoung/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tenthyoung',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
